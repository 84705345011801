import React, { useState, useEffect, useRef } from 'react';
import DynamicCanvas from './DynamicCanvas';
import {games} from './gameRef';
import styled from 'styled-components';
import {getGameInfo} from './api/apiCaller';

export default function DebugPage(props) {
  const [rendererCode, setRendererCode] = useState('');
  const [gameState, setGameState] = useState(games[props.gameType].demoState);
  const [userId, setUserId] = useState(games[props.gameType].demoUserId);
  const [perspectiveFromPlayerIdx, setPerspectiveFromPlayerIdx] = useState((props.perspectiveFromPlayerIdx === undefined) ? -1 : props.perspectiveFromPlayerIdx);


  useEffect(() => {
    if (!props.gameType) {
      return;
    }
    getGameInfo(props.gameType, (data) => {
      setRendererCode(data['canvas_renderer_code']);
    });
  }, [props.gameType]);

  return (
    <CanvasWrapperDiv width={props.canvasWidth} height={props.canvasHeight}>
      <DynamicCanvas 
        gameState={gameState}
        userId={userId}
        gameType={props.gameType}
        rendererAsString={rendererCode}
        perspectiveFromPlayerIdx={perspectiveFromPlayerIdx}
        assetMetadata={games[props.gameType].assetMetadata}
      />
    </CanvasWrapperDiv>
  );

  
}

const CanvasWrapperDiv = styled.div`
  display: flex;
  width: ${({width}) => (width || 500) + "px"};
  height: ${({height}) => (height || 500) + "px"}
`