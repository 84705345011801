const dev = {
  env: 'development',
  botpot_origin: 'http://localhost:3000',
  storybook_origin: 'http://localhost:9009',
  server: {
    BASE_URL: "http://localhost:8080/"
  }
};

const prod = {
  env: 'production',
  botpot_origin: 'https://botpot.ai',
  preview_origin: "https://preview.botpot.ai",
  storybook_origin: 'https://botpot.ai',
  server: {
    BASE_URL: "https://api.botpot.ai/"
  }
};

const config = (process.env.REACT_APP_ENV === 'production' || process.env.STORYBOOK_APP_ENV === 'production')
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};