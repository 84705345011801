import config from '../config';

function defaultErrorHandler(error) {
  console.log(error);
}

async function getGameInfo(gameType, 
  responseHandler = (d) => {},
  notFoundHandler = defaultErrorHandler
  ) {
    const response = await fetch(config.server.BASE_URL + 'api/v1/creategame/?game_type=' + gameType);
    if (response.ok) {
      responseHandler(await response.json());
    } else if (response.status === 404) {
      notFoundHandler(await response.json());
    }
}

export {
  getGameInfo
}