import React, { useState, useEffect, useRef } from 'react';
import AssetsLoader from './AssetsLoader';

export default function DynamicCanvas(props) {
  const [assets, setAssets] = useState(undefined);
  const [renderer, setRenderer] = useState(undefined);
  const assetLoader = AssetsLoader({
    assetMetadata: props.assetMetadata,
    onLoaded: (assetData) => {
      setAssets(assetData);
    }
  });
  const canvasRef = useRef(null);

  const isNeededAssetLoaded = () => {
    if (!props.assetMetadata) {
      return true; // don't need to load any assets
    }
    return assets !== undefined;
  }

  useEffect(() => {
    if (!props.rendererAsString) {
      return;
    }
    setRenderer(() => new Function(
      "{canvasRef, gameState, perspectiveFromPlayerIdx, assets} = {}", // input into the canvas renderer function
      props.rendererAsString
    ));
  }, [props.rendererAsString]);

  useEffect(() => {
    if (!props.gameState || !isNeededAssetLoaded() || renderer === undefined) {
      return;
    }
    let perspectiveFromPlayerIdx = (props.perspectiveFromPlayerIdx === null) ? -1 : props.perspectiveFromPlayerIdx;
    
    try {
      renderer({
        canvasRef: canvasRef, 
        gameState: props.gameState, 
        perspectiveFromPlayerIdx: perspectiveFromPlayerIdx,
        assets: assets
      });
    } catch (e) {
      console.error(e);
    }
  }, [props.gameState, renderer, props.assetMetadata, props.perspectiveFromPlayerIdx, assets]);
  

  return (
    <canvas ref={canvasRef} width={500} height={500}/>
  );
}

