import React, { useEffect, useRef } from 'react';
import DynamicCanvasController from './DynamicCanvasController';
import DebugPage from './DebugPage';
import ThreeCanvas from './ThreeCanvas';
import { BrowserRouter as Router, Route, Routes, useLocation, useParams, useSearchParams } from 'react-router-dom';

function RenderHomePage(props) {
  const [searchParams] = useSearchParams();
  return (
  <DynamicCanvasController
    canvasWidth={searchParams.get("width")}
    canvasHeight={searchParams.get("height")}
  />);
}

function RenderDebugPage(props) {
  const { gameType } = useParams();
  const [searchParams] = useSearchParams();
  return (
    <DebugPage
      gameType={gameType}
      canvasWidth={searchParams.get("width")}
      canvasHeight={searchParams.get("height")}
    />
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path='/' element={<RenderHomePage/>}/>
          <Route exact path='/debug/:gameType' element={<RenderDebugPage/>}/>
          <Route exact path='/three' element={<ThreeCanvas/>}/>
          <Route path='*' element={<RenderHomePage/>}/>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
