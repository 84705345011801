import unoAssetJson from './games/unoAssets.json';
import big2AssetJson from './games/big2Assets.json';
import riskAssetJson from './games/riskAssets.json';
import tutorialAssetJson from './games/tutorialAssets.json';
import jaipurAssetJson from './games/jaipurAssets.json';

export const games = {
  'tictactoe': {
    gameType: 'tictactoe',
    gameName: 'Tic Tac Toe',
    demoState: {
      board: [-1, -1, -1, 0, 1, 0, 1, 0, -1]
    },
    demoUserId: "Bob"
  },
  'quoridor': {
    gameType: 'quoridor',
    gameName: 'Quoridor',
    demoState: {
      user1: "Bob",
      user2: "James",
      player_positions: {"Bob": {"row": 0, "column": 4}, "James": {"row": 8, "column": 4}},
      walls: [{"row": 3, "column": 5, "is_horizontal": true}, {"column": 7, "row": 0, "is_horizontal": false}, {"column": 0, "row": 0, "is_horizontal": false}, {"row": 1, "column": 0, "is_horizontal": true}, {"row": 7, "column": 7, "is_horizontal": false}],
      remaining_wall_count: {"Bob": 7, "James": 8}
    },
    demoUserId: "Bob"
  },
  'connectfour': {
    gameType: 'connectfour',
    gameName: 'Connect Four',
    demoState: {
      user1: "Nina",
      user2: "Don",
      user_turn: "Nina",
      board: [
        1, 0, 0, 0, -1, -1, -1,
        1, -1, -1, 1, -1, -1, -1,
        -1, -1, -1, 1, -1, -1, -1,
        -1, -1, -1, 1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1
      ]
    },
    demoUserId: "Nina"
  },
  'battleship': {
    gameType: 'battleship',
    gameName: 'Battleship',
    demoState: {
      user1: "Bob",
      user2: "James",
      user_ships: [
        {"positions": [{"x": 3, "y": 5}, {"x": 3, "y": 6}]},
        {"positions": [{"x": 5, "y": 2}, {"x": 5, "y": 3}, {"x": 5, "y": 4}]},
        {"positions": [{"x": 0, "y": 0}, {"x": 1, "y": 0}, {"x": 2, "y": 0}]},
        {"positions": [{"x": 7, "y": 9}, {"x": 7, "y": 8}, {"x": 7, "y": 6}, {"x": 7, "y": 7}]},
        {"positions": [{"x": 8, "y": 0}, {"x": 8, "y": 1}, {"x": 8, "y": 2}, {"x": 8, "y": 3}, {"x": 8, "y": 4}]}
        ],
      user1_sunken_ship_sizes: [2, 3],
      user2_sunken_ship_sizes: [2],
      shots_at_user1: [{"x": 3, "y": 5, "did_hit": true}, {"x": 3, "y": 6, "did_hit": true}, {"x": 0, "y": 2, "did_hit": false}, {"x": 0, "y": 3, "did_hit": false}, {"x": 9, "y": 9, "did_hit": false}],
      shots_at_user2: [{"x": 0, "y": 2, "did_hit": true}, {"x": 0, "y": 0, "did_hit": true}, {"x": 0, "y": 1, "did_hit": true}, {"x": 5, "y": 4, "did_hit": true}, {"x": 5, "y": 5, "did_hit": true}],
      state: "USER1_SHOT"
    },
    demoUserId: "Bob"
  },
  'viruswar': {
    gameType: 'viruswar',
    gameName: 'Virus War',
    demoState: {
      user1: "Bob",
      user2: "James",
      required_move_count: 3,
      visible_viruses: [ { "is_zombie": false, "user": null, "x": 0, "y": 1 }, { "is_zombie": false, "user": null, "x": 1, "y": 2 }, { "is_zombie": false, "user": null, "x": 2, "y": 1 }, { "is_zombie": false, "user": null, "x": 0, "y": 0 }, { "is_zombie": false, "user": "Bob", "x": 1, "y": 1 }, { "is_zombie": true, "user": "James", "x": 2, "y": 0 }, { "is_zombie": false, "user": null, "x": 0, "y": 2 }, { "is_zombie": false, "user": null, "x": 2, "y": 2 }, { "is_zombie": false, "user": null, "x": 1, "y": 0 } ],
      board_width: 7,
      board_height: 7
    },
    demoUserId: "Bob"
  },
  'dotsandboxes': {
    gameType: 'dotsandboxes',
    gameName: 'Dots and boxes',
    demoState: {
      user1: "Bob",
      user2: "James",
      user_turn: "Bob",
      board_width: 2,
      board_height: 2,
      board: [{"row": 0, "column": 0, "user_closed_box": '', "top_line" : 'Bob', "bottom_line": '', "right_line": '', "left_line": ''},
       {"row": 0, "column": 1, "user_closed_box": 'Bob', "top_line" : 'Bob', "bottom_line": 'Bob', "right_line": 'Bob', "left_line": 'Bob'},
       {"row": 1, "column": 0, "user_closed_box": 'James', "top_line" : 'James', "bottom_line": 'James', "right_line": 'James', "left_line": 'James'},
       {"row": 1, "column": 1, "user_closed_box": '', "top_line" : '', "bottom_line": '', "right_line": '', "left_line": ''}]
    },
    demoUserId: "Bob"
  },
  'hex': {
    gameType: 'hex',
    gameName: 'Hex',
    demoState: {
      user1: "Bob",
      user2: "James",
      pieces: [{ row: 0, column: 0, user: "Bob" },
        { row: 5, column: 3, user: "James" },
        { row: 10, column: 9, user: "James" },
        { row: 0, column: 5, user: "Bob" }
      ]
    },
    demoUserId: "Bob"
  },
  'reversi': {
    gameType: 'reversi',
    gameName: 'Reversi',
    demoState: {
      'game_board': [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 0, 1, -1, -1, -1, -1, -1, -1, 1, 0, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1], 
      'valid_moves': [{'index': 20, 'valid_directions': ['BELOW']}, {'index': 29, 'valid_directions': ['LEFT']}, {'index': 34, 'valid_directions': ['RIGHT']}, {'index': 43, 'valid_directions': ['ABOVE']}]
    },
    demoUserId: "Bob"
  },
  'gomoku': {
    gameType: 'gomoku',
    gameName: 'Gomoku',
    demoState: {
      'board': []
    },
    demoUserId: "Bob"
  },
  'mancala': {
    gameType: 'mancala',
    gameName: 'Mancala',
    demoState: {
      'board': [0, 4, 4, 4, 4, 4, 4, 0, 4, 4, 4, 4, 4, 4]
    },
    demoUserId: "Bob"
  },
  'uno': {
    gameType: 'uno',
    gameName: 'Uno',
    demoState: {
      'user_turn': 'peter',
      'player_hand': {
        'cards': [{
          'color': 'UNDEFINED',
          'type': 'WILD',
          'card_number': -1
        }, {
          'color': 'UNDEFINED',
          'type': 'WILDDRAWFOUR',
          'card_number': -1
        }, {
          'color': 'RED',
          'type': 'DRAWTWO',
          'card_number': -1
        }, {
          'color': 'BLUE',
          'type': 'DRAWTWO',
          'card_number': -1
        }, {
          'color': 'YELLOW',
          'type': 'SKIP',
          'card_number': -1
        }, {
          'color': 'GREEN',
          'type': 'NUMBERED',
          'card_number': 5
        }, {
          'color': 'RED',
          'type': 'NUMBERED',
          'card_number': 0
        }]
      },
      'players': ['bob', 'james', 'peter', 'adam', 'john', 'asdf'],
      'players_hand_count': [7, 7, 7, 7, 7, 7],
      'last_discarded': {
        'color': 'YELLOW',
        'card_number': 9,
        'type': 'NUMBERED'
      },
      'direction': 'CLOCKWISE',
      'scores': [0,0,0,0,100,321,0],
      'draw_pile_count': 86
    },
    demoUserId: "peter",
    assetMetadata: unoAssetJson
  },
  'big2': {
    gameType: 'big2',
    gameName: 'Big Two',
    demoState: {
      'user_turn': 'peter',
      'player_hand': {
        'cards': [{
          'suit': 'DIAMOND',
          'card_rank': 5
        }, {
          'suit': 'HEART',
          'card_rank': 8
        }, {
          'suit': 'HEART',
          'card_rank': 9
        }, {
          'suit': 'DIAMOND',
          'card_rank': 10
        }, {
          'suit': 'CLUB',
          'card_rank': 10
        }, {
          'suit': 'HEART',
          'card_rank': 10
        }, {
          'suit': 'DIAMOND',
          'card_rank': 11
        }, {
          'suit': 'CLUB',
          'card_rank': 11
        }, {
          'suit': 'SPADE',
          'card_rank': 12
        }, {
          'suit': 'HEART',
          'card_rank': 13
        }, {
          'suit': 'CLUB',
          'card_rank': 1
        }, {
          'suit': 'HEART',
          'card_rank': 1
        }, {
          'suit': 'DIAMOND',
          'card_rank': 2
        }]
      },
      'players': ['bob', 'james', 'peter', 'adam'],
      'players_hand_count': [8, 13, 2, 5],
      'passes': 0,
      'discard_pile': {},
      'last_play': {
        'type': 'DOUBLE',
        'cards': [{
          'suit': 'DIAMOND',
          'card_rank': 3
        }, {
          'suit': 'CLUB',
          'card_rank': 3
        }]
      }
    },
    demoUserId: "peter",
    assetMetadata: big2AssetJson
  },
  'snaketetris': {
    gameType: 'snaketetris',
    gameName: 'Snake & Tetris',
    demoState: {
      user1: "Bob",
      snake: {
        positions: [
          {x:5, y:5}, {x:6, y:5}, {x:6, y:4}, {x:7, y:4}, {x:8, y:4}, {x:8, y:3}]
      },
      food: {x: 8, y: 1},
      tetris_board: {
        pieces: [
          {x:0, y:0, piece_name:'O'}, {x:1, y:0, piece_name:'O'}, {x:1, y:1, piece_name:'O'}, {x:0, y:1, piece_name:'O'},
          {x:7, y:0, piece_name:'L'}, {x:8, y:0, piece_name:'L'}, {x:9, y:0, piece_name:'L'}, {x:9, y:1, piece_name:'L'}],
        next_tetrominos: [
          {
            positions: [
              {x:0, y:0}, {x:1, y:0}, {x:1, y:1}, {x:2, y:1}
            ],
            piece_name:'S',
            offset_x: 4,
            offset_y: 18
          }
        ]
      },
      score: 5
    },
    demoUserId: "Bob"
  },
  'risk': {
    gameType: 'risk',
    assetMetadata: riskAssetJson
  },
  'tutorial': {
    gameType: 'tutorial',
    assetMetadata: tutorialAssetJson
  },
  'catandmouse': {
    gameType: 'catandmouse',
    assetMetadata: tutorialAssetJson
  },
  'jaipur': {
    gameType: 'jaipur',
    assetMetadata: jaipurAssetJson
  }
};