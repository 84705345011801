import React, { useState, useEffect, useRef } from 'react';

export default function AssetsLoader(props) {
  const [loadedAssetKeys, setLoadedAssetKeys] = useState(new Set());
  const [assets, setAssets] = useState(undefined);

  useEffect(() => {
    if (!props.assetMetadata || assets) {
      return;
    }
    let assetsTmp = {}
    for (const [key, metadata] of Object.entries(props.assetMetadata)) {
      if (metadata.type === "image") {
        let image = new Image();
        image.src = metadata.url;
        image.onload = () => {
          setLoadedAssetKeys(loaded => new Set([...loaded, key]))
        };
        assetsTmp[key] = image;
      } else if (metadata.type === "json") {
        let url = metadata.url;
        fetch(url, {
          method: "GET",
          headers: {
            "origin": "http://localhost:3001",
            'Access-Control-Allow-Origin': "*",
          }
        })
          .then(response => response.json())
          .then(data => {
            assetsTmp[key] = data;
            setLoadedAssetKeys(loaded => new Set([...loaded, key]));
        });
      }
    }
    setAssets(assetsTmp);
  }, [props.assetMetadata, assets]);

  useEffect(() => {
    if (!props.assetMetadata) {
      return;
    }
    if (Object.keys(props.assetMetadata).length === loadedAssetKeys.size) {
      props.onLoaded(assets);
    }
  }, [loadedAssetKeys, props.assetMetadata]);

  return null;
}
