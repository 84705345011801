import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import config from './config';
import DynamicCanvas from './DynamicCanvas';
import {games} from './gameRef';
import UseConstructor from './UseConstructor';
import {getGameInfo} from './api/apiCaller';

export default function DynamicCanvasController(props) {
  const [gameState, setGameState] = useState(null);
  const [gameType, setGameType] = useState(null);
  const [perspectiveFromPlayerIdx, setPerspectiveFromPlayerIdx] = useState(null);
  const [assetMetadata, setAssetMetadata] = useState(null);
  const [rendererString, setRendererString] = useState(undefined);
  const [isReceivingEvents, setIsReceivingEvents] = useState(false);

  UseConstructor(() => {
    window.addEventListener("message", receiveMessage, false);
    function receiveMessage(event)
    {
      if (event.origin !== config.botpot_origin && event.origin !== config.storybook_origin && event.origin !== config.preview_origin) {
        return;
      }
      setGameState(event.data.gameState);
      setGameType(event.data.gameType);
      setPerspectiveFromPlayerIdx(event.data.perspectiveFromPlayerIdx);
      setAssetMetadata(games[event.data.gameType]?.assetMetadata); // TODO: pass this in too
    }
  });

  useEffect(() => {
    if (!gameType) {
      return;
    }
    getGameInfo(gameType, (data) => {
      setRendererString(data['canvas_renderer_code']);
    });
  }, [gameType]);

  return (
    <CanvasWrapperDiv width={props.canvasWidth} height={props.canvasHeight}>
      <DynamicCanvas 
        gameState={gameState}
        gameType={gameType}
        perspectiveFromPlayerIdx={perspectiveFromPlayerIdx}
        assetMetadata={assetMetadata}
        rendererAsString={rendererString}
      />
    </CanvasWrapperDiv>
  );
}

const CanvasWrapperDiv = styled.div`
  display: flex;
  width: ${({width}) => (width || 500) + "px"};
  height: ${({height}) => (height || 500) + "px"}
`