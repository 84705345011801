import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Canvas, useFrame } from '@react-three/fiber';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { useLoader } from '@react-three/fiber';

function Box(props) {
  // This reference gives us direct access to the THREE.Mesh object
  const ref = useRef()
  // Hold state for hovered and clicked events
  const [hovered, hover] = useState(false)
  const [clicked, click] = useState(false)
  // Subscribe this component to the render-loop, rotate the mesh every frame
  useFrame((state, delta) => (ref.current.rotation.x += 0.01))
  // Return the view, these are regular Threejs elements expressed in JSX
  return (
    <mesh
      {...props}
      ref={ref}
      scale={clicked ? 1.5 : 1}
      onClick={(event) => click(!clicked)}
      onPointerOver={(event) => hover(true)}
      onPointerOut={(event) => hover(false)}>
      <boxGeometry args={[1, 1, 1]} />
      <meshPhongMaterial color={hovered ? 'hotpink' : 'orange'} />
    </mesh>
  )
}

export default function ThreeCanvas(props) {

  return (
    <CanvasWrapperDiv>
      <Canvas>
        <ambientLight/>
        <directionalLight 
          color={'0x03ffa0'}
          intensity={1.0}
          position={[0.5, 1, 0]}
        />
        <Box position={[1, 0.5, 0.5]}/>
        <Box position={[-1, 0.5, 0.5]}/>
      </Canvas>
    </CanvasWrapperDiv>
  );
}

const CanvasWrapperDiv = styled.div`
  width: 500px;
  height: 500px;
`